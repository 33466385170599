import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import moment from "moment";
import { AiFillStar } from "react-icons/ai";

const ReviewCard = ({ review }) => {
	var trimmedString = review.reviewBody.substr(0, 180);
	trimmedString =
		trimmedString.substr(
			0,
			Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
		) + "...";

	const [reviewBody, setReviewBody] = useState(review.reviewBody);
	const [linkText, setlinkText] = useState("Read More");
	const [expanded, setExpanded] = useState(false);

	const handleClick = () => {
		console.log("clicked");
		if (!expanded) {
			setReviewBody(review.reviewBody);
			setlinkText("Read Less");
			setExpanded(true);
		} else {
			setReviewBody(trimmedString);
			setlinkText("Read More");
			setExpanded(false);
		}
	};
	if (!expanded) setReviewBody(trimmedString);
	return (
		<Card
			style={{ boxShadow: "0px 3px 50px #00000029", borderRadius: "10px" }}
			className="border-0 review-card h-100 px-md-4 position-relative mx-0"
		>
			<Card.Header
				style={{ borderRadius: "10px" }}
				className="bg-white pt-5 border-0"
			>
				<Row className="align-items-center">
					<Col xs={9}>
						<Card.Title className="inter-bold mb-0 mb-md-1 mb-lg-0">
							{review.author.toUpperCase()}
						</Card.Title>
						<p className="pb-0 mb-0" style={{ fontSize: "1.2rem" }}>
							{moment(review.datePublished).format(" DD MMM YYYY")}
						</p>
					</Col>
					<Col className="text-end" xs={3}>
						<p className="inter-bold d-inline-block fs-5 pb-0 mb-0 pe-1 ">5</p>
						<AiFillStar className="mb-4 text-text-secondary" />
					</Col>
				</Row>
			</Card.Header>
			<Card.Body>
				<Row>
					<Col>
						<Card.Text>
							{review.reviewBody.length <= 180 && <p>{review.reviewBody}</p>}
							{review.reviewBody.length > 180 && <p>{reviewBody}</p>}
						</Card.Text>
					</Col>
				</Row>
				{review.reviewBody.length > 180 && (
					<Row>
						<Col>
							<div className="mt-4" style={{ height: "30px" }}></div>
							<p
								className="text-secondary-link  pointer position-absolute bottom-0 pb-3 "
								onClick={handleClick}
							>
								{linkText}
							</p>
						</Col>
					</Row>
				)}
			</Card.Body>
		</Card>
	);
};

export default ReviewCard;
