import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import ReviewCard from "../components/review-card";
import Layout from "../components/layout";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://www.local-marketing-reports.com/external/showcase-reviews/widgets/d63dd8fdcef2ba52b7ea9a082bee8598fd2e98e9`
      )
      .then((res) => {
        const reviewData = res.data.results;
        setReviews(reviewData);
        setReviews(reviewData);
        document.getElementById("loading-spinner").style.display = "none";
        document.getElementById("no-reviews").style.display = "block";
      });
  });

  const data = useStaticQuery(
    graphql`
      query {
        heroImg: wpMediaItem(title: { eq: "Image-Part-1-CU" }) {
          altText
          localFile {
            publicURL
            childImageSharp {
              original {
                height
                width
              }
              gatsbyImageData(
                formats: [AUTO, WEBP]
                quality: 100
                transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                layout: CONSTRAINED
                placeholder: BLURRED
              )
            }
          }
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
        pageData: wpPage(slug: {eq: "reviews"}) {
          seoFieldGroups {
            metaTitle
            metaDescription
            localBusinessSchema
            openGraphDescription
            openGraphTitle
            productSchema
            image {
              node {
                altText
                publicUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          reviewsPageFields {
            title
            subtitle
          }
        }
      }
    `
  );

  const {
    pageData: { seoFieldGroups, reviewsPageFields, slug },
  } = data;


  const siteUrl = data.site.siteMetadata.siteUrl;
  const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Reviews",
        item: {
          url: `${siteUrl}/reviews`,
          id: `${siteUrl}/reviews`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/reviews`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <section className="pb-lg-5">
        <Container>
          <Row>
            <Col className="text-center mb-5 mt-5">
              <p className="text-text-secondary jost-bold fs-6">{reviewsPageFields?.subtitle || "REVIEWS"}</p>
              <h2 className=" display-4 ">{reviewsPageFields.title || "Our google reviews"}</h2>
            </Col>
          </Row>
        </Container>
      </section>
      <Container className="pb-5">
        <Col>
          <Row className=" " style={{ minHeight: "50vh" }}>
            <Spinner
              className="mx-auto"
              id="loading-spinner"
              variant="primary"
              animation="border"
            >
              <span className="sr-only"></span>
            </Spinner>

            {reviews.map((review) => (
              <Col className="pb-5" lg={4}>
                {" "}
                <ReviewCard review={review} />
              </Col>
            ))}
          </Row>
        </Col>
      </Container>
    </Layout>
  );
};

export default Reviews;
